import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
         transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
        >
         
<path d="M857 3963 c-4 -3 -7 -681 -7 -1505 l0 -1498 1650 0 1650 0 -2 1503
-3 1502 -1641 3 c-902 1 -1644 -1 -1647 -5z m1696 -270 c-29 -2 -77 -2 -105 0
-29 2 -6 3 52 3 58 0 81 -1 53 -3z m-206 -9 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z m336 -1 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2
18 -5z m64 -9 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m68 -14
c13 -5 14 -9 5 -9 -8 0 -24 4 -35 9 -13 5 -14 9 -5 9 8 0 24 -4 35 -9z m52
-16 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m48 -14 c13 -6 15
-9 5 -9 -8 0 -22 4 -30 9 -18 12 -2 12 25 0z m75 -30 c19 -11 31 -19 25 -19
-5 0 -26 8 -45 19 -19 11 -30 19 -25 19 6 0 26 -8 45 -19z m106 -51 c3 -5 -7
-4 -23 3 -41 17 -53 30 -15 17 18 -6 35 -15 38 -20z m-578 -69 c0 -8 -6 1 -13
20 -7 19 -14 29 -14 21 -1 -8 -4 -12 -7 -8 -3 3 -2 16 2 29 7 22 8 21 20 -12
7 -19 12 -42 12 -50z m178 68 c5 -7 3 -8 -6 -3 -7 4 -16 2 -21 -6 -7 -11 -9
-10 -9 4 0 19 25 23 36 5z m-223 -52 c4 4 7 0 7 -9 0 -26 -19 -20 -32 11 l-11
27 -7 -30 c-8 -30 -8 -30 -9 -3 0 14 2 34 6 43 5 15 9 12 23 -14 9 -18 19 -29
23 -25z m-231 -11 c-3 -16 0 -25 8 -25 7 0 10 7 6 15 -9 25 11 17 30 -12 15
-24 15 -26 1 -14 -14 11 -20 11 -39 -1 -23 -15 -23 -15 -19 28 1 24 6 42 11
39 4 -2 5 -16 2 -30z m441 -13 c12 2 27 14 34 28 9 17 13 20 13 8 0 -24 -28
-48 -56 -48 -21 0 -24 4 -23 33 2 31 2 31 6 4 4 -22 9 -28 26 -25z m229 -24
c2 -21 0 -38 -4 -38 -4 0 -8 16 -8 36 0 23 -4 33 -11 28 -6 -3 -9 -14 -6 -24
3 -13 0 -16 -15 -12 -18 4 -17 7 9 34 15 16 29 25 30 21 1 -4 3 -25 5 -45z
m-827 -28 c16 -31 21 -54 6 -31 -13 21 -44 5 -38 -19 8 -32 -10 -23 -24 13 -7
17 -15 37 -17 42 -2 6 2 1 10 -9 20 -25 53 -14 45 14 -7 29 0 25 18 -10z
m1021 -30 c17 7 23 7 19 0 -3 -6 -12 -10 -20 -10 -7 0 -23 -12 -35 -27 -15
-20 -18 -22 -11 -6 6 12 12 37 12 55 2 29 2 30 6 6 4 -24 7 -26 29 -18z m166
-141 c10 14 20 23 22 20 2 -2 -9 -19 -25 -37 l-29 -32 0 42 0 43 -20 -25 c-11
-14 -20 -21 -20 -16 0 6 10 21 23 34 l22 24 5 -39 5 -38 17 24z m244 -244 c3
-13 4 -30 1 -37 -3 -7 -6 -1 -6 15 -1 30 -8 32 -46 15 -34 -15 -28 -41 10 -49
l30 -7 -30 -1 c-21 -1 -34 6 -44 21 -8 12 -10 23 -5 25 5 2 23 12 39 22 40 26
44 25 51 -4z m-1138 -42 c-9 -2 -21 -18 -27 -35 -10 -30 -16 -248 -6 -248 2 0
15 11 29 25 16 16 36 25 57 25 l31 0 -7 48 c-8 56 -11 61 -39 72 -14 6 11 9
77 9 75 1 97 4 97 15 0 39 -14 75 -34 84 -16 8 1 11 74 11 66 0 88 -3 73 -9
-22 -9 -30 -29 -36 -79 -2 -22 3 -26 42 -33 76 -13 131 -63 157 -141 24 -71
16 -237 -14 -293 -49 -95 -122 -124 -315 -124 -129 0 -129 0 -106 19 23 19 24
23 24 182 l0 163 -33 10 c-22 6 -40 5 -57 -2 -24 -11 -25 -16 -25 -94 0 -97
14 -155 38 -161 9 -3 -23 -5 -72 -6 l-88 -1 23 19 24 19 0 247 0 247 -24 19
-23 19 88 -1 c49 -1 81 -3 72 -6z m-987 -140 c-6 -22 -15 -81 -20 -131 -16
-156 11 -326 78 -490 11 -29 16 -50 10 -46 -9 5 -10 0 -7 -14 4 -13 2 -20 -4
-16 -5 3 -7 18 -3 33 4 17 3 23 -3 16 -6 -5 -12 -19 -14 -30 -5 -28 -54 134
-69 228 -16 103 -7 340 16 430 10 37 20 66 22 63 2 -2 0 -22 -6 -43z m2219
-22 c0 -5 -7 -12 -16 -15 -8 -3 -12 -2 -9 4 3 6 -3 10 -14 10 -12 0 -21 -6
-21 -12 0 -9 -3 -10 -8 -2 -4 6 -14 14 -22 17 -8 3 9 6 38 6 28 1 52 -3 52 -8z
m99 -61 c0 -9 -4 -8 -9 5 -5 11 -9 27 -9 35 0 9 4 8 9 -5 5 -11 9 -27 9 -35z
m-139 26 c0 -2 -7 -9 -15 -16 -13 -11 -14 -10 -9 4 5 14 24 23 24 12z m157
-153 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-65 0 c4 -15 -3
-18 -46 -19 l-51 -2 43 10 c23 5 42 13 42 19 0 15 7 10 12 -8z m75 -200 c-2
-32 -3 -8 -3 52 0 61 1 87 3 58 2 -29 2 -78 0 -110z m-69 -14 c-19 -12 -28
-11 -25 3 1 6 -6 14 -15 16 -11 2 -18 -2 -18 -12 0 -9 6 -16 13 -17 6 0 1 -5
-12 -10 -13 -5 -26 -7 -29 -4 -3 3 0 5 6 5 16 0 15 43 0 58 -7 7 11 3 40 -9
37 -15 49 -25 40 -30z m-2158 -395 c6 17 8 16 23 -8 10 -14 15 -26 11 -26 -7
0 54 -79 78 -101 19 -17 25 -45 8 -34 -5 3 -10 0 -10 -8 0 -8 8 -14 17 -13 10
0 18 -3 18 -9 0 -5 4 -15 8 -22 4 -6 4 -19 1 -30 -5 -15 -4 -15 5 -3 9 13 13
12 33 -7 12 -13 17 -23 11 -23 -7 0 -17 8 -22 18 -6 9 -11 12 -11 6 0 -6 22
-34 49 -62 29 -30 45 -54 39 -58 -13 -9 -132 115 -185 193 -23 33 -57 92 -76
129 -39 79 -60 141 -26 79 18 -33 23 -37 29 -21z m427 -260 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m160 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z m-120 -30 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m-125 -146 c-9 -9 -9 -15 1 -27 11 -14 10 -14 -6 -2 -13 10 -15 17 -6
27 6 8 14 14 17 14 3 0 1 -5 -6 -12z m35 -43 c0 -8 -4 -12 -9 -9 -5 3 -6 10
-3 15 9 13 12 11 12 -6z m198 -15 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0
13 -4 16 -10z m1059 -65 c-10 -8 -23 -14 -29 -14 -5 0 -1 6 9 14 11 8 24 15
30 15 5 0 1 -7 -10 -15z m-44 -19 c0 -2 -35 -19 -77 -37 -102 -42 -211 -69
-238 -59 -18 8 -18 9 -1 9 12 1 16 6 12 17 -3 8 -2 13 2 10 4 -2 16 2 27 10
18 14 18 14 -3 14 -12 0 -22 3 -22 8 0 4 42 6 92 4 61 -2 109 2 138 12 47 15
70 19 70 12z m-325 -16 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10
5 0 13 -4 16 -10z"/>
<path d="M2543 2843 c-9 -3 -13 -27 -13 -70 0 -56 3 -66 20 -70 11 -3 20 -11
20 -17 0 -6 -9 -19 -20 -29 -18 -15 -20 -29 -20 -132 0 -91 3 -115 14 -115 28
0 41 33 46 124 l5 91 24 -65 c13 -36 29 -69 36 -75 6 -5 15 -23 19 -38 3 -15
17 -32 29 -37 15 -6 -8 -9 -76 -9 l-98 -1 3 -47 3 -48 71 1 c39 1 84 8 100 15
34 17 70 68 86 123 16 57 16 211 -1 270 -17 57 -77 126 -111 126 l-22 0 3
-150 c2 -82 1 -146 -3 -142 -21 22 -62 156 -67 222 -6 75 -14 88 -48 73z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
